import { RouterComp } from './router/RouterComp';

function App() {
  return (
   
      <div>   
        <RouterComp />
      </div>
 

  );
}

export default App;
