import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ScrollComponent } from '../components/ScrollComponent'
import { VideoScroll } from '../components/VideoScroll'
import { Navbar } from '../layouts/Navbar'
import { GoToTop } from '../components/GoToTop'
import { Error404 } from '../components/Error404'
import { UnderConstruction } from '../components/UnderConstruction'

export const RouterComp = () => {
  return (
    <BrowserRouter>
    <GoToTop />
      <Navbar />
      <section>
        <div>
          <Routes>
            <Route path='/' element={<ScrollComponent />} />
            <Route path='/video' element={<VideoScroll />} />
            <Route path='/sound' element={ <UnderConstruction />}/>
            <Route path='*' element={ <Error404 /> } />
          </Routes>
        </div>
      </section>
    </BrowserRouter>
  )
}
