import React from 'react'

export const Error404 = () => {
  return (
    <>
        <div className='uppercase flex m-auto justify-center h-screen align-middle items-center'>
            <div className='block'>
            <h1>Error404</h1>
            <h2>Page not found</h2>
            </div>  
        </div>
    </>
  )
}
