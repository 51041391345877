import React from 'react'
import { gsap } from 'gsap/gsap-core'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger, TextPlugin } from 'gsap/all'
import { objects, technologies } from './data'
import { Footer } from '../layouts/Footer'
import { Tooltip } from '@material-tailwind/react'

export const ScrollComponent = () => {

    //reset scrollbar position after refresh
    //  window.onbeforeunload = function() {
    //     window.scrollTo(0, 0);
    // }

    
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);

    useGSAP(() => {
        //let speed = 100;
        const tl1 = gsap.timeline();

        ScrollTrigger.create(
            {
                animation: tl1,
                trigger: '.trigger-box',
                start: "top top", // 'start scroller-start' i.e. 'top center' means: when the top of the trigger element meets the center of the viewport, then the animation is gonna start
                end: "100% 100%", // 'end scroller-end' 
                scrub: 0.3, // true :la animación se sincroniza con el ratón (numero: la animación se sincroniza con el ratón pero se detiene con un ligero retraso para suavizar)
                pin: '.main-box',
                //markers: true,
                //onUpdate: (self) => console.log("progress:", self.progress),
            }
        );

        tl1.fromTo('.main-box', {

            pin: true
        },
            { pin: false })
            .fromTo(".cama", { opacity: 0, yPercent: 200 }, {
                yPercent: 0,
                opacity: 1,
                ease: "power3.out",
            }, 0)
            .fromTo(".librero", { opacity: 0, yPercent: -200 }, {
                yPercent: 0,
                opacity: 1,
                ease: "power3.out"
            }, 0.3)
            .fromTo(".buro-comp", { opacity: 0 }, {
                opacity: 1,
            }, 0.8)
            .fromTo(".buro-taza", { opacity: 0, yPercent: 200 }, {
                yPercent: 0,
                opacity: 1,
                ease: "power4.out"
            }, 0.2)
            .fromTo(".calentador", { opacity: 0 }, {
                opacity: 1,
            }, 0.9)
            .fromTo(".entrepanio", { opacity: 0, yPercent: -300 }, {
                yPercent: 0,
                opacity: 1,
                ease: "power3.out"
            }, 0.5)
            .fromTo(".pantallas", { opacity: 0, xPercent: -100 }, {
                xPercent: 0,
                opacity: 1,
                ease: "power3.out"
            }, 0.5)
            .fromTo(".silla", { opacity: 0.5, xPercent: 200 }, {
                xPercent: 0,
                opacity: 1,
                ease: 'power4.out'
            }, 0.9)
            .fromTo(".scroll-button", { opacity: 1 }, {
                opacity: 0,
                ease: 'expo.out'
            }, 0.3);

        const tl2 = gsap.timeline();
        tl2.to(".title-small", {
            duration: 2,
            text: {
                value: "DEVELOPMENT",
            },
        })
            .to(".title-sentence", {
                duration: 3,
                delay: 1,
                text: {
                    value: "Java Script, Python, WebGL, MERN (MongoDB, Express, ReactJS, NodeJS), HTML, CSS, and more... ",
                },
            });


        let proxy = { skew: 0 };
        let skewSetter = gsap.quickSetter(".gallery", "skewY", "deg");
        let clamp = gsap.utils.clamp(-10, 10); //acotar a 10 grados

        ScrollTrigger.create({
            onUpdate: (self) => {
                let skew = clamp(self.getVelocity() / -750);
                if (Math.abs(skew) > Math.abs(proxy.skew)) {
                    proxy.skew = skew;
                    gsap.to(proxy, {
                        skew: 0,
                        duration: 1.5,
                        ease: "power3",
                        overwrite: true,
                        onUpdate: () => skewSetter(proxy.skew)
                    });
                }
            }
        });


    });//useGSAP


    return (
        <>
            <div className='trigger-box'></div>
            <div className='main-box bg-no-repeat xl:bg-contain'>
                <div className='cama bg-no-repeat xl:bg-contain'></div>
                <div className='librero bg-no-repeat xl:bg-contain'></div>
                <div className='buro-comp bg-no-repeat xl:bg-contain'></div>
                <div className='buro-taza bg-no-repeat xl:bg-contain'></div>
                <div className='calentador bg-no-repeat xl:bg-contain'></div>
                <div className='entrepanio bg-no-repeat xl:bg-contain'></div>
                <div className='pantallas bg-no-repeat xl:bg-contain'></div>
                <div className='silla bg-no-repeat xl:bg-contain'></div>

                {/* TITLE */}
                <div className='hero-title'>
                    <p className='title-big'>MultiVersions</p>
                    <p className='title-small'></p>
                    <p className='title-sentence'></p>

                    {/* SCROLL */}
                    <div className='scroll-button'>

                        <div className='scroll-title'>
                            <p className='turn-me'>Turn your device in horizontal orientation and</p>
                            <p className='scroll-down'>Scroll down</p>
                        </div>
                        {/* scroll */}
                        <div id="mouse-scroll">
                            <div className="mouse">
                                <div className="mouse-in"></div>
                            </div>
                            <div className='arrow'>
                                <span className="down-arrow-1"></span>
                                <span className="down-arrow-2"></span>
                                <span className="down-arrow-3"></span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='gallery-container'>
                        <div className='gallery'>
                            <div className='gallery-card'>
                                 <img src={objects.title} alt='wanted' />
                            </div>
                            <div className='gallery-card ml-4 mr-4'>
                                 <img src={objects.source} alt='wanted' />
                            </div>  
                        </div>
                    </div> */}


            </div>
            <div className='gallery-container'>
                <div className='gallery-title'>
                    <p>Some technology, sites and apps...</p>
                </div>
                <div className='tech-container'>
                    {technologies.map(tech => (
                        <Tooltip key={tech.id} content={tech.alt}
                        animate={{
                            mount: { scale: 1, y: 0 },
                            unmount: { scale: 0, y: 25 },
                        }}>
                             <img className='tech-img' src={tech.technology} alt={tech.alt} />
                        </Tooltip>
                    ))}
                </div>
                <div className='gallery'>

                    {objects.map(object => (
                        <div key={object.id} className='gallery-card'>
                            <div className='card-title'><p> {object.title} </p> </div>
                            <img className='card-img' src={object.source} alt={object.alt} />
                            <div ><p className='card-description'>{object.description} </p></div><br />
                            <div> <p className='card-tech'> {object.tech} </p></div>
                            <a className='card-link' href={object.link} target='_blank rel=noopener'>Visit site</a>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    )
}
