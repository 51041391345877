import React, { lazy, Suspense} from 'react'
import { gsap } from 'gsap/gsap-core'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger, TextPlugin } from 'gsap/all'
import { software, works } from './data'
import { Tooltip } from '@material-tailwind/react'
import {
    blackMagic,
    nikkon,
    p2,
    micro,
    tascam,
    zoom,
    dji,
} from '../assets/assets'
import { FooterVideo } from '../layouts/FooterVideo'
//import { Video } from './Video'
const Video = lazy(() => import('../components/Video').then(module => { return { default: module.Video } }));



export const VideoScroll = () => {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);

    useGSAP(() => {
        // const bgVideo = document.querySelector("#bgVideo");

        // bgVideo.pause();
        // bgVideo.currentTime = 0;

        let sections = gsap.utils.toArray(".step");

        sections.forEach((step, i) => {
            ScrollTrigger.create({
                trigger: step,
                start: "bottom bottom",
                end: "+=2000",
                pin: true,
                anticipatePin: 1,
                //markers: true,
            });

            gsap.from(`#step${i}-paragraph`,
                {
                    xPercent: 200,
                    duration: 1,
                    ease: 'bounce',
                    scrollTrigger: {
                        trigger: `#step${i}`,
                        start: "top top",
                        //markers: true,
                    }
                })

            // gsap.fromTo(bgVideo, { currentTime: 3 * i }, {
            //     scrollTrigger: {
            //         trigger: step,
            //         scrub: 2,
            //         start: "top bottom",
            //         end: "bottom bottom",
            //     },
            //     currentTime: 3 * (i + 1),
            //     duration: 1,
            //     ease: "none",
            // });
        });

        gsap.to(".bgVideo", {
            scrollTrigger: {
                scrub: true
            },
            scale: 1.5
        });

        ////TEXT ANIMATION

        const tl2 = gsap.timeline();
        tl2.to(".title-small", {
            duration: 1.5,
            text: {
                value: "AUDIOVISUAL PRODUCTION",
            },
        })
            .to(".title-sentence", {
                duration: 3,
                delay: 1,
                text: {
                    value: 'Camera, sound, light, video edition, motion graphics and infinite visions.',
                },
            });

        // let proxy = { translate: 0 };
        // let translateSetter = gsap.quickSetter("img", "translateY", "px");
        // let clamp = gsap.utils.clamp(-50, 50); //acotar a 20 px

        // ScrollTrigger.create({
        //     onUpdate: (self) => {
        //         let translate = clamp(self.getVelocity() / -600);
        //         if (Math.abs(translate) > Math.abs(proxy.translate)) {
        //             proxy.translate = translate;
        //             gsap.to(proxy, {
        //                 translate: 0,
        //                 duration: 0.7,
        //                 ease: 'elastic',
        //                 overwrite: true,
        //                 onUpdate: () => translateSetter(proxy.translate)
        //             });
        //         }
        //     }
        // });

    });//useGSAP

    return (
        <>
        <Suspense fallback={<div>LOADING...</div>}>
           <Video />
        </Suspense>
            

            <div id="scroll-video" className='scroll-video'>
                <section id="step0" className='step'>
                    <div className='video-hero-title'>

                        {/* TITLE */}
                        <div className='hero-title-video'>
                            <p className='title-big'>MultiVersions</p>
                            <p className='title-small'></p>
                            <p className='title-sentence'></p>
                        </div>

                    </div>
                    <div>
                        {/* SCROLL */}
                        <div className='scroll-button-video'>

                            <div className='scroll-title'>
                                {/* <p className='turn-me'>Turn your device in horizontal orientation and</p> */}
                                <p className='scroll-down'>Scroll down</p>
                            </div>
                            {/* scroll */}
                            <div id="mouse-scroll">
                                <div className="mouse">
                                    <div className="mouse-in"></div>
                                </div>
                                <div className='arrow'>
                                    <span className="down-arrow-1"></span>
                                    <span className="down-arrow-2"></span>
                                    <span className="down-arrow-3"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section id="step1" className="step">
                    <div id='step1-child' className='step-child'>
                        <p id='step0-paragraph' className='about'>Infinite visions! Crafting captivating audiovisual experiences that ignite the imagination and leave lasting impressions. Passion for storytelling to push boundaries and redefine the art of visual communication. Only a visionary creative expert can do it.</p>
                        <div className='pics'>
                            <img className='camera-picture' src={blackMagic} alt='blackmagic URSA' />
                            <img className='camera-picture' src={nikkon} alt='Nikkon Z7' />
                            <img className='camera-picture' src={p2} alt='Panasonic P2' />
                        </div>
                        <p id='step1-paragraph' className='step-paragraph'><span>Cameras: </span>Black magic URSA, Panasonic P2, Nikkon Z7, Canon E0S 5D, Sony-DSR-450WSP.</p>
                    </div>

                </section>
              
                <section id="step2" className="step">
                    <div id='step2-child' className='step-child'>
                        <div className='pics'>
                            {software.map(soft => (
                                <Tooltip key={soft.id} content={soft.alt}
                                    animate={{
                                        mount: { scale: 1, y: 0 },
                                        unmount: { scale: 0, y: 25 },
                                    }}>
                                    <img className='software' src={soft.img} alt={soft.alt} />
                                </Tooltip>
                            ))}
                        </div>
                        <p id='step2-paragraph' className='step-paragraph'><span>Software: </span>Adobe Suite (Premiere Pro, After Effects, Illustrator, Photoshop, Encoder), Final Cut Pro X, Logic Pro X, Pro Tools, Unity 3D.</p>
                    </div>
                </section>
                <section id="step3" className="step">
                    <div id='step3-child' className='step-child'>
                        <div className='pics'>
                            <img className='gear' src={dji} alt='dji stabilizer' />
                            <img className='gear' src={tascam} alt='Tascam portable device' />
                            <img className='gear' src={zoom} alt='Zomm H6' />
                            <img className='gear' src={micro} alt='Microphone' />
                        </div>
                        <p id='step3-paragraph' className='step-paragraph'>Multiple toys such as stabilizers, audio devices, microphones, etc.</p>
                    </div>
                </section>
                <section id="step4" className="step">
                    <div id='step4-child' className='step-child'>

                        <div className='pics'>
                            {works.map(work => (
                                <Tooltip key={work.id} content={work.alt}
                                    animate={{
                                        mount: { scale: 1, y: 0 },
                                        unmount: { scale: 0, y: 25 },
                                    }}>
                                    <img className='experience' src={work.img} alt={work.alt} />
                                </Tooltip>
                            ))}
                        </div>
                        <p id='step4-paragraph' className='step-paragraph'>Professional experience in different environments such as cultural, business, institutional and the media.</p>
                    </div>
                    <button className='ml-auto mr-auto p-4 footer-button' onClick={() => { window.scroll(0, 0) }}>
                        Back to top
                    </button>
                    <FooterVideo />
                </section>                            
            </div>


        </>
    )
}
