import React from 'react'
import { gsap } from 'gsap/gsap-core'
import { useGSAP } from '@gsap/react'
import { TextPlugin } from 'gsap/all'
import { FooterVideo } from '../layouts/FooterVideo'

export const UnderConstruction = () => {

    gsap.registerPlugin(TextPlugin);

    useGSAP(() => {

        const tl1 = gsap.timeline();
        tl1.to(".under-construction-2", {
                duration: 3,
                delay: 1,
                text: {
                    value: "page ",
                },
            }, 1);
    });
  return (
    <div className='uppercase flex m-auto justify-center h-screen align-middle items-center'>
        <section className='block'>
        <h1 className='uppercase text-4xl'>
            <span className='under-construction-1'>This </span>
            <span className='under-construction-2'>universe</span>
            <span className='under-construction-3'> is under construction...</span>
        </h1>
        <FooterVideo />
        </section> 
        
    </div>
  )
}
