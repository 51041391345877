//sites
import { wanted, frases, iss, fashion, toiletchat, parametric, joke, blog, fbi } from '../assets/assets'
//teccnology
import { css, git, html, java, mongo, node, react, tailwind, three, python, mysql  } from '../assets/assets'
//software
import { apr, ae, ai, ap,finalCut, logic, pro, unity } from '../assets/assets';
//works
import { cjue, luxe, moloss, rtbf, canalC } from '../assets/assets';

const works = [
    {
        id: '1',
        img: cjue,
        alt: 'Court of Justice of the European Union'
    },
    {
        id: '2',
        img: luxe,
        alt: 'LuxeTV'
    },
    {
        id: '3',
        img: moloss,
        alt: 'Moloss film'
    },
    {
        id: '4',
        img: rtbf,
        alt: 'Radio-télévision belge de la Communauté française'
    },
    {
        id: '5',
        img: canalC,
        alt: 'Canal C'
    },
];

const software = [
    {
        id: '1',
        img: apr,
        alt: 'Adobe Premiere Pro'
    },
    {
        id: '2',
        img: ae,
        alt: 'Adobe After Effects'
    },
    {
        id: '3',
        img: ai,
        alt: 'Adobe Illustrator'
    },
    {
        id: '4',
        img: ap,
        alt: 'Adobe Photoshop'
    },
    {
        id: '5',
        img: finalCut,
        alt: 'Final Cut Pro'
    },
    {
        id: '6',
        img: logic,
        alt: 'Logic Pro X'
    },
    {
        id: '7',
        img: pro,
        alt: 'Pro Tools'
    },
    {
        id: '8',
        img: unity,
        alt: 'Unity3D'
    },
];

const objects = [

    {   
        id: '1',
        title: 'Wanted',
        description:'Full stack site designed for report lost/found animals and services.',
        tech: 'VueJS, Firebase, Vuetify.',
        source: wanted,
        link: 'https://vortexxi.github.io/wanted/',
        alt: 'Wanted app'
    },

    {   
        id: '2',
        title: 'I.S.S. tracker',
        description:'Site designed for tracking and display the International Space Station position in real time and watch its streaming.',
        tech: 'Playcanvas (WebGL), Rellax.js, Leaflet.js, Mapbox, Firebase, RestAPI.',
        source: iss,
        link: 'https://iss-coordinates.web.app/',
        alt: 'I.S.S. tracker'
    },

    {
        id: '3',
        title: 'Toiletchat',
        description: 'Real time anonymous chat web app.',
        tech: 'Bootstrap, Java Script, Socket.io, render.com.',
        source: toiletchat,
        limk: 'https://toiletchat.onrender.com/',
        alt: 'Toiletchat'
    },

    {   
        id: '4',
        title: 'BLOG',
        description: 'Full stack blog app.',
        tech: 'MERN stack (MongoDB, Express, ReacJS, NodeJS), Rest API.',
        source: blog,
        link: 'https://github.com/vortexxi/MERN-blog-template',
        alt: 'MultiVersions blog app'
    },

    {   
        id: '5',
        title: 'PARAMETRIC DESIGN ART GALLERY',
        description: '3D WebGL experience. Take a tour into a virtual museum and enjoy 4 parametric design art pieces.',
        tech: 'Unity 3D.',
        source: parametric,
        link: 'https://i.simmer.io/@vortexxi/parametric-art-gallery',
        alt: 'Parametric design virtual art gallery'
    },

    {   
        id: '6',
        title: 'FASHION E-COMMERCE TEMPLATE',
        description: 'E-commerce site template with 3D visors and cart features.',
        tech: 'Vue js, Vuetify js, Snipcart, Cappasity.',
        source: fashion,
        link: 'https://vortexxi.github.io/fashion-store/',
        alt: 'Fashion e-commerce template'
    },

    {
        id: '7',
        title: 'SPANISH-RUSSIAN SENTENCE GENERATOR',
        description: 'Web app desogned for build multiple sentences from Spanish to Russian and listen for its pronunciation.',
        tech: 'HTML, Buzz.js, Bootstrap.',
        source: frases,
        link: 'https://vortexxi.github.io/frases-espanol-ruso/',
        alt: 'Spanish-Russian phrases'
    },

    {
        id: '8',
        title: 'JOKER',
        description: 'Click the button and take a laugh with a joke generator app.',
        tech: 'Parallax.js, HTML, CSS, Rest API.',
        source: joke,
        link: 'https://joker.multiversions.com',
        alt: 'Joker app'
    },
    {
        id: '9',
        title: 'FBI 20 MOST WANTED',
        description: 'The FBI most wanted. Updated through the FBI public API. Deployed in render.com.',
        tech: 'ReactJS, Rest API.',
        source: fbi,
        link: 'https://fbi-most-wanted-qf9i.onrender.com/',
        alt: 'FBI 20 most wanted app'
    },
];

const technologies = [
    {
        id: '0',
        technology: python,
        alt: 'Python'
    },
    {
        id: '1',
        technology: css,
        alt: 'CSS',
    },
    {
        id: '2',
        technology: git,
        alt: 'Git'
    },
    {
        id: '3',
        technology: html,
        alt: 'HTML5'
    },
    {
        id: '4',
        technology: java,
        alt: 'Java Script'
    },
    {
        id: '5',
        technology: mongo,
        alt: 'MongoDB'
    },
    {
        id: '6',
        technology: node,
        alt: 'NodeJS'
    },
    {
        id: '7',
        technology: react,
        alt: 'ReactJS'
    },
    {
        id: '8',
        technology: tailwind,
        alt: 'Tailwind'
    },
    {
        id: '9',
        technology: three,
        alt: 'ThreeJS'
    },
    {
        id: '10',
        technology: mysql,
        alt: 'mySQL'
    },
]

export { objects, technologies, software, works };
