import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const GoToTop = () => {
    //use the hook
    const {routePath} = useLocation();
    //scroll to top function
    const onTop = () => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
          console.log('top');
    }
    //use effect to update state
    useEffect(() => {
        onTop()
    }, [routePath]);
    //returns null because we don’t want to render any visible content on the page, it just needs to handle the scrolling behavior.
    return null;
}
